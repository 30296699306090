import { FC, useEffect, useState } from 'react'
import SlickSlider from '../../../../components/SlickSlider'

import Image from 'next/image'
import { getIsServer } from '../../../../utillities/getIsServer'
import { useBannerTrackingClick, useBannnerPositionGroup } from '../../../../hooks/banners/useBanners'

const Banners: FC<{}> = () => {
  const isMobile = getIsServer() || window.innerWidth <= 576
  const [banners, setBanners] = useState<any>()

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const [bannerGroup] = useBannnerPositionGroup(8, 5)

  useEffect(() => {
    if (Object.keys(bannerGroup).length > 0) {
      if (isMobile) {
        setBanners(bannerGroup[2])
      } else {
        setBanners(bannerGroup[1])
      }
    }
  }, [isMobile, bannerGroup])

  return (
    <div className="banners wrapper-container">
      <SlickSlider {...settings}>
        {banners?.map((banner) => {
          return (
            <a className="banner-item" onClick={useBannerTrackingClick(banner.id)} href={banner.url} key={banner.id} target="blank">
              <Image
                className="banners-img"
                quality={100}
                src={banner?.file?.url}
                alt={banner.url}
                width={isMobile ? 343 : 1200}
                height={isMobile ? 180 : 200}
              />
            </a>
          )
        })}
      </SlickSlider>
    </div>
  )
}

export default Banners
