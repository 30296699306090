import { FC } from 'react'
import SlickSlider from '../../../../components/SlickSlider'
import { Trans } from 'react-i18next'
import { useTranslation } from '../../../../hooks/useTranslation'
import StarFilled from '../../../../components/base/icons/StarFilled'
import Image from 'next/image'
import ViewSalary from '../../../company/[slug]/NormalCompany/components/ViewSalary'
import ArrowRight from '../../assets/img/arrow_right.svg'
import SuggestedCheckIcon from '../../assets/img/suggested-check.svg'
import { LINK_URL_COMPANY_PUBLISH_JOBS, LINK_URL_JOB_DETAILS } from '../../../../utillities/URL'
import { translateAsPathFromTo } from '../../../../utillities/TranslationUtils'
import ListSkill from './ListSkill'
import { Tooltip } from '../../../../../core/lib/components/share/ANTD'
import LOGO_DEFAULT from '../../assets/img/logo_default.webp'

const HotJobs: FC<{ sliderData }> = (sliderData) => {
  const { translate, currentLanguage } = useTranslation()
  const settings = {
    infinite: true,
    arrows: true,
    accessibility: true,
    prevArrow: (
      <button className="slick-prev">
        <Image src={ArrowRight} alt="arrow-left" className="img-fluid" />
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <Image src={ArrowRight} alt="arrow-right" className="img-fluid" />
      </button>
    ),
    rows: 3,
    dots: true,
    speed: 500,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          rows: 6,
          slidesPerRow: 1
        }
      }
    ]
  }

  const renderLocations = (hotJob) => {
    if (hotJob.attributes.locations.data.length > 0) {
      let hotJobLocations = hotJob.attributes.locations.data.map((location) =>
        currentLanguage === 'en' ? location.attributes.state : location.attributes.state_vn
      )
      hotJobLocations = hotJobLocations.filter((value, index) => hotJobLocations.indexOf(value) === index)

      return (
        <Tooltip title={hotJobLocations.join(', ')}>
          {hotJobLocations[0]}
          {hotJobLocations.length - 1 > 0 && ` & ${hotJobLocations.length - 1} ${translate('page_home:other_places')}`}
        </Tooltip>
      )
    }
  }

  return (
    <div className="hot-jobs">
      <div className="wrapper-container">
        <div className="hot-jobs__heading">
          <h2 className="hot-jobs__title">
            <Trans i18nKey="page_home:lp_job_opportunity">
              <span className="high-light" />
            </Trans>
          </h2>
          <div className="hot-jobs__sub-title">{translate('page_home:lp_exciting_job_for_you')}</div>
        </div>
        <div className="hot-jobs__slider">
          <SlickSlider {...settings}>
            {sliderData?.sliderData?.map((hotJob) => {
              return (
                <div className="hot-job" key={hotJob.id}>
                  {hotJob.attributes.company?.data?.attributes?.verified && (
                    <div className="hot-job__check-icon">
                      <Image alt="veirfy" width={16} height={16} src={SuggestedCheckIcon.src} />
                    </div>
                  )}

                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex align-items-start hot-job__left-col">
                      <a
                        className="hot-job__company-logo"
                        href={
                          hotJob.attributes.company?.data?.attributes?.custom_career_url ||
                          LINK_URL_COMPANY_PUBLISH_JOBS(
                            hotJob.attributes.company?.data?.attributes?.slug,
                            hotJob.attributes.company?.data?.attributes?.id
                          )
                        }
                        // href={translateLink(`${env.PUBLIC_APP_DOMAIN}/company/${hotJob.attributes.company?.data?.attributes?.slug}`)}
                      >
                        <Image
                          alt={hotJob.attributes.company?.data?.attributes?.name}
                          width={48}
                          height={48}
                          src={
                            hotJob.attributes.company?.data?.attributes?.logo_url
                              ? hotJob.attributes.company?.data?.attributes?.logo_url
                              : LOGO_DEFAULT
                          }
                        />
                      </a>
                      <div>
                        <a
                          className="hot-job__title"
                          href={translateAsPathFromTo(
                            LINK_URL_JOB_DETAILS(hotJob.attributes.slug, hotJob.attributes.id),
                            'en',
                            currentLanguage
                          )}>
                          {hotJob.attributes.title}
                        </a>
                        <a
                          className="hot-job__company-link"
                          href={
                            hotJob.attributes.company?.data?.attributes?.custom_career_url ||
                            LINK_URL_COMPANY_PUBLISH_JOBS(
                              hotJob.attributes.company?.data?.attributes?.slug,
                              hotJob.attributes.company?.data?.attributes?.id
                            )
                          }>
                          {hotJob.attributes.company?.data?.attributes?.name}
                        </a>
                        <div className="hot-job__salary">
                          <ViewSalary
                            negotiable={hotJob.attributes.negotiable}
                            min_salary={hotJob.attributes.min_salary}
                            max_salary={hotJob.attributes.max_salary}
                            salary_currency={hotJob.attributes.salary_currency}
                            salary_type={hotJob.attributes.salary_type}
                            hide_salary={hotJob.attributes.hide_salary}
                          />
                        </div>

                        <div className="hot-job__location">{renderLocations(hotJob)}</div>
                      </div>
                    </div>
                    {hotJob.attributes.hot_job && (
                      <div className="hot-job__tag">
                        <span>HOT</span>&nbsp;
                        <StarFilled />
                      </div>
                    )}
                  </div>
                  {hotJob.attributes.job_skills_attribute?.data?.length > 0 && (
                    <ListSkill skillsList={hotJob.attributes.job_skills_attribute?.data} />
                  )}
                </div>
              )
            })}
          </SlickSlider>
        </div>
      </div>
    </div>
  )
}

export default HotJobs
