import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from '../../../../hooks/useTranslation'
// import { useAppRouter } from '../../../../../core/lib/hooks/UseAppRouter'

import Image from 'next/image'

// import BACKGROUND from '../../assets/img/top_hiring_bg.webp'
import EnvironmentOutlined from '../../../../components/base/icons/EnvironmentOutlined'
import Row from '../../../../components/base/Grid/Row'
import Col from '../../../../components/base/Grid/Col'
import SlickSlider from '../../../../components/SlickSlider'

// import ArrowRight from '../../assets/img/arrow_right.svg'
import { LINK_URL_COMPANY_PUBLISH_JOBS } from '../../../../utillities/URL'

import LOGO_DEFAULT from '../../assets/img/logo_default.webp'
const TopHiringCompaniesLPDesktop: FC<{ slidersData }> = ({ slidersData }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerPadding: 24,
    dots: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 577,
        settings: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  }
  const { translate, currentLanguage } = useTranslation()
  // const router = useAppRouter()
  return (
    <div className="top-hiring">
      <div className="wrapper-container">
        <div className="heading">
          <h2 className="title">
            <Trans i18nKey="page_home:lp_top_hiring_companies">
              <span className="high-light" />
            </Trans>
          </h2>
          <div className="sub-title">{translate('page_home:more_than_100_jobs')}</div>
        </div>
        {slidersData.length > 0 ? (
          <Row justify="center">
            <Col md={24}>
              <div className="slider-company">
                {slidersData.length > 6 ? (
                  <SlickSlider {...settings}>
                    {slidersData.slice(0, 6).map((item) => (
                      <a
                        target="blank"
                        className="company-card"
                        key={item.companyId}
                        href={
                          item?.custom_career_url
                            ? item?.custom_career_url
                            : LINK_URL_COMPANY_PUBLISH_JOBS(item.companySlug, item.companyId)
                        }
                        // onClick={() =>
                        //   router.push(
                        //     item?.custom_career_url
                        //       ? item?.custom_career_url
                        //       : LINK_URL_COMPANY_PUBLISH_JOBS(item.companySlug, item.companyId)
                        //   )
                        // }
                      >
                        <div className="wrapper-image">
                          <Image src={item.logoUrl ? item.logoUrl : LOGO_DEFAULT} alt="search" width={148} height={124} />
                        </div>
                        <div className="company-name">{item.companyName}</div>

                        <div className="d-flex align-items-center justify-content-center company-location">
                          {item.locationName ? (
                            <>
                              <EnvironmentOutlined className="icon-location" />
                              <div>{currentLanguage === 'en' ? item.locationName : item.locationNameVn}</div>
                            </>
                          ) : null}
                        </div>
                        <div className="company-jobs">
                          <Trans i18nKey="page_home:number_jobs_hiring" values={{ number: item.availabaleJobNumber }} />
                        </div>
                      </a>
                    ))}
                  </SlickSlider>
                ) : (
                  <>
                    <div className="company-wrapper d-flex justify-content-center">
                      {slidersData.map((item) => (
                        <a
                          target="blank"
                          className="company-card"
                          key={item.companyId}
                          href={
                            item?.custom_career_url
                              ? item?.custom_career_url
                              : LINK_URL_COMPANY_PUBLISH_JOBS(item.companySlug, item.companyId)
                          }
                          // onClick={() =>
                          //   router.push(
                          //     item?.custom_career_url
                          //       ? item?.custom_career_url
                          //       : LINK_URL_COMPANY_PUBLISH_JOBS(item.companySlug, item.companyId)
                          //   )
                          // }
                        >
                          <div className="wrapper-image">
                            <Image src={item.logoUrl ? item.logoUrl : LOGO_DEFAULT} alt="search" width={146} height={121} />
                          </div>
                          <div className="company-name">{item.companyName}</div>

                          <div className="d-flex align-items-center justify-content-center company-location">
                            {item.locationName ? (
                              <>
                                <EnvironmentOutlined className="icon-location" />
                                <div>{currentLanguage === 'en' ? item.locationName : item.locationNameVn}</div>
                              </>
                            ) : null}
                          </div>
                          <div className="company-jobs">
                            <Trans i18nKey="page_home:number_jobs_hiring" values={{ number: item.availabaleJobNumber }} />
                          </div>
                        </a>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(TopHiringCompaniesLPDesktop)
